/* eslint-disable */
/* generated by @svgr/cli */
import * as React from 'react';

function SvgLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" {...props}>
      <path fill="#2ab0e9" fillRule="evenodd" d="M262.607 182.696h304.302V292.57H262.607z" />
      <path fill="#86d2f3" fillRule="evenodd" d="M330.772 395.7H478.4v53.856H330.772z" />
      <path fill="#b5e3f7" fillRule="evenodd" d="M371.467 474.193h97.081v28.49h-97.081z" />
      <path fill="#e3f5fc" fillRule="evenodd" d="M393.483 527.321h85.577v24.637h-85.577z" />
      <path fill="#1694ca" fillRule="evenodd" d="M210.224 48.184h425.86v109.874h-425.86z" />
      <path fill="#58c2ee" fillRule="evenodd" d="M298.512 317.207h215.741v53.856H298.512z" />
    </svg>
  );
}

export default SvgLogo;
